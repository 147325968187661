import { default as GatewayBase } from './gateway-base';

export default class MetadataService extends GatewayBase {
  get notes() {
    return {
      create: this.createNote.bind(this),
      get: this.getNotes.bind(this),
    };
  }

  get metadata() {
    return {
      add: this.addMetadata.bind(this),
      get: this.getMetadata.bind(this),
    };
  }

  private getNotes(id: string) {
    return this.client.get(`v1/notes/${id}`);
  }

  private createNote(
    id: string,
    input: { username: string; userId: string; threadId?: string; content: string; media?: any[] },
  ) {
    return this.client.post(`v1/notes/${id}`, input);
  }

  private getMetadata(subjectId: string) {
    return this.client.get(`v1/metadata/${subjectId}`);
  }

  private addMetadata(
    subjectId: string,
    input: { key: string; value: string; addedByUserId?: string; addedByUsername?: string },
  ) {
    return this.client.post(`v1/metadata/${subjectId}`, input);
  }
}
